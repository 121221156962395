<template>
  <div class="news-card">
    <div class="top-circles">
      <img :src="item.image" class="circle-image" />
      <img
        v-if="item.userPic"
        :src="item.userPic"
        class="circle-user-pic overlapped"
      />
    </div>
    <div class="horizontal-box-content">
      <span class="title">{{ item.title }}</span>
      <span class="description">{{ item.description }}</span>
    </div>
    <div class="reactions">
      <span class="reaction" v-for="reaction in reactions" :key="reaction">
        {{ reaction }} 0
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reactions: ["👍", "❤️", "😆", "😢", "😮"],
    };
  },
};
</script>

<style scoped>
.news-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 170px;
  margin: 0px 15px;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin-bottom: 30px;
  background-color: var(--whappy-primary);
  color: white;
  overflow: visible;
}

.top-circles {
  position: relative;
  display: flex;
  justify-content: center;
  margin: -30px 0 -20px 0;
}

.circle-image,
.circle-user-pic {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  z-index: 100;
}

.circle-user-pic.overlapped {
  margin-left: -20px;
  z-index: 200;
}

.horizontal-box-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 10px 10px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.description {
  font-size: 10px;
  margin-bottom: 0;
}

.reactions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0 10px;
  min-height: 35px;
  height: auto;
}

.reaction {
  font-size: 14px;
  margin: 0 5px;
}

/* Animation for reaction click */
.reaction-clicked {
  animation: pulse-animation 0.5s ease-in-out;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
