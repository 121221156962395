<template>
  <div class="notification-page user-page">
    <div class="content-wrapper">
      <h2>{{ campaignName }}</h2>
    </div>
    <b-card>
      <h4 class="mb-0">Custom notifications</h4>
      <b-row>
        <b-col md="9" xl="6" class="pt-4">
          <div
            class="nestable-whappy-item d-flex align-items-center mb-3"
            v-for="notification in notifications_list"
            :key="notification.id"
          >
            <span class="ml-2">
              <b-form-checkbox
                v-model="notification.Enabled"
                switch
                class="n-0"
                @change="toggleEnableNotification(notification)"
              >
              </b-form-checkbox>
            </span>
            <span class="ml-2"
              ><strong>{{ notification.title }}</strong></span
            >
            <div
              class="
                tools-box
                align-self-stretch
                d-flex
                align-items-center
                pl-2
                ml-auto
              "
            >
              <b-icon
                icon="gear-fill"
                class="pointer"
                v-b-toggle.sidebar-notification
                @click="
                  currentNotification = notification;
                  tmpNotification = JSON.parse(JSON.stringify(notification));
                "
              ></b-icon>
              <b-icon
                @click="deleteNotification(notification.ID)"
                icon="trash-fill"
                class="pointer"
              ></b-icon>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-button
        squared
        size="lg"
        class="button-dashed big-button mt-5"
        v-b-toggle.sidebar-notification
        ><b-icon-plus />Add Custom Notification</b-button
      >
    </b-card>
    <!-- <b-button
      v-if="isDebug"
      class="fixed-button py-2"
      v-b-toggle.sidebar-iframe
    >
      <img src="../../assets/images/phone.png" class="img-fluid" />
    </b-button> -->
    <!-- <b-sidebar
      @hidden="resetDataSidebarIframe"
      class="right-sidebar iframe-sidebar"
      id="sidebar-iframe"
      width="500px"
      right
      shadow
      lazy
    >
      <div class="login-as">
        <h4>Login as:</h4>
        <b-select
          @change="getUrlDemo"
          :options="usersList"
          v-model="userSelected"
        >
        </b-select>
        user selectd: {{ userSelected }} || url demo: {{ url_demo }}
      </div>
      <div class="iframe-phone">
        <iframe
          class="iframe-phone-iframe"
          :src="url_demo"
          frameborder="0"
        ></iframe>
      </div>
    </b-sidebar> -->
    <b-sidebar
      class="right-sidebar"
      id="sidebar-notification"
      right
      shadow
      lazy
      @hidden="resetSidebar"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="currentNotification">Edit Notification</h4>
        <h4 v-else>New Notification</h4>
        <b-form-group label="Title" label-for="title">
          <b-form-input v-model="tmpNotification.title" />
          <LangOptions v-model="title_lang" :options="lang_options" />
        </b-form-group>
        <b-form-group class="mt-5 mb-2">
          <template #label>
            Define the condition under which the notification is sent
            <InfoPopover
              class="ml-1"
              text="Select the condition that will trigger the notification"
            />
            <!-- <pre>{{ { tmpNotification, options } }}</pre> -->
            <b-form-select
              v-model="tmpNotification.notificationType"
              :options="options"
            ></b-form-select>
          </template>
          <!-- notification condition box -->
          <div>
            <div
              v-if="
                selected_option_obj.filter == 'award' ||
                  selected_option_obj.filter == 'badge' ||
                  selected_option_obj.filter == 'kpi' ||
                  selected_option_obj.filter == 'learning' ||
                  selected_option_obj.filter == 'social'
              "
            >
              <div class="mb-2">
                <b-checkbox v-model="tmpNotification.allObjects">
                  Includi tutti gli item
                </b-checkbox>
              </div>
              <div
                v-if="checkboxList.length > 0 && !tmpNotification.allObjects"
              >
                <b-form-checkbox-group
                  v-model="tmpNotification.objectsID"
                  class="p-2"
                >
                  <div class="checkbox-container">
                    <b-form-checkbox
                      v-for="item in checkboxList"
                      :key="item.value"
                      :value="item.value"
                      class="mb-2 mt-1"
                    >
                      <strong> {{ item.text }}</strong>
                    </b-form-checkbox>
                  </div>
                </b-form-checkbox-group>
              </div>
              <div v-else-if="!tmpNotification.allObjects">
                There are no "{{ selected_option_obj.filter }}" options
                available
              </div>
              <div v-else>All options selected</div>
            </div>
            <div v-else-if="selected_option_obj.filter == 'classroom'" x>
              <div>
                <b-form-group label-for="Ore" label-cols="3" label="Ore">
                  <b-form-input
                    v-model="time.ore"
                    id="Ore"
                    type="number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="Minuti" label-cols="3" label="Minuti">
                  <b-form-input
                    v-model="time.minuti"
                    id="Minuti"
                    type="number"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div v-if="checkboxList.length > 0">
                <b-form-checkbox-group
                  v-model="tmpNotification.objectsID"
                  class="p-2"
                >
                  <div class="checkbox-container">
                    <b-form-checkbox
                      v-for="item in checkboxList"
                      :key="item.value"
                      :value="item.value"
                      class="mb-2 mt-1"
                    >
                      <strong>
                        {{ item.text }}
                      </strong>
                    </b-form-checkbox>
                  </div>
                </b-form-checkbox-group>
              </div>
            </div>

            <div v-else-if="selected_option_obj.filter == ''"></div>
          </div>
        </b-form-group>

        <div class="w-full my-4">
          <b-row>
            <b-col cols="6">
              <b-card title="Notifica">
                <b-form-group>
                  <b-form-select
                    :options="notificationStyleOptions"
                    v-model="tmpNotification.notificationStyle"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  class="mt-5"
                  label="Message (use @ to acritivate properties)"
                  label-for="description-en"
                >
                  <b-form-input v-model="tmpNotification.title" />

                  <Mentionable
                    :keys="['@']"
                    :items="selected_option_obj.placeholders"
                    offset="6"
                  >
                    <b-form-textarea
                      v-model="tmpNotification.message"
                      rows="3"
                    />
                  </Mentionable>
                  <LangOptions
                    v-model="description_lang"
                    :options="lang_options"
                  />
                </b-form-group>

                <span class="font-bold text-lg">Anteprima</span>

                <div
                  v-if="tmpNotification.notificationStyle == 'base'"
                  class="notification notification-base"
                >
                  <div class="top-avatar">
                    <img
                      src="../../assets/images/MR_placeholder_400x400.png"
                      alt=""
                    />
                  </div>
                  <span
                    class="flex flex-column justify-content-center align-items-center"
                    ><span class="font-bold text-lg mb-4">{{
                      tmpNotification.title
                    }}</span>
                    <span>{{ tmpNotification.message }}</span>
                  </span>
                  <div></div>
                </div>

                <div
                  v-if="tmpNotification.notificationStyle == 'feedback'"
                  class="notification notification-feedback"
                >
                  <div class="top-avatar">
                    <img
                      src="../../assets/images/MR_placeholder_400x400.png"
                      alt=""
                    />
                  </div>

                  <div class="rating-section">
                    <span
                      v-for="star in 5"
                      :key="star"
                      class="star"
                      :class="{ filled: star <= rating }"
                      @click="setRating(star)"
                      >&#9733;</span
                    >
                  </div>

                  <div class="emoji-section">
                    <span
                      v-for="(emoji, index) in emojis"
                      :key="index"
                      class="emoji"
                      :class="{ selected: selectedEmoji === index }"
                      @click="setEmoji(index)"
                      >{{ emoji }}</span
                    >
                  </div>

                  <b-form-group>
                    <b-form-textarea
                      style="width: 300px; height: 150px;"
                      placeholder="Aggiungi anche un commento..."
                    >
                    </b-form-textarea>
                  </b-form-group>

                  <b-button class="big-button" squared size="lg">
                    Invia
                  </b-button>
                </div>

                <div
                  v-if="tmpNotification.notificationStyle == 'relazione'"
                  class="notification notification-relazione"
                >
                  <div class="top-avatar">
                    <img
                      src="../../assets/images/MR_placeholder_400x400.png"
                      alt=""
                    />
                  </div>

                  <span class="font-bold text-lg">{{
                    tmpNotification.title
                  }}</span>
                  <span>{{ tmpNotification.message }}</span>

                  <div class="bottom-avatar">
                    <img
                      src="../../assets/images/MR_placeholder_400x400.png"
                      alt=""
                    />
                  </div>

                  <b-button squared size="lg" class="big-button">
                    Fai recensione
                  </b-button>
                </div>
              </b-card>
            </b-col>
            <b-col cols="6">
              <b-card title="Social interno">
                <b-form-group
                  class="mt-5"
                  label="Message (use @ to acritivate properties)"
                  label-for="description-en"
                >
                  <b-form-input
                    v-model="tmpNotification.socialTitle"
                    placeholder="Titolo"
                  ></b-form-input>

                  <Mentionable
                    :keys="['@']"
                    :items="selected_option_obj.placeholders"
                    offset="6"
                  >
                    <b-form-textarea
                      v-model="tmpNotification.socialMessage"
                      rows="3"
                    />
                  </Mentionable>
                  <LangOptions
                    v-model="description_lang"
                    :options="lang_options"
                  />
                </b-form-group>

                <span class="font-bold text-lg">Anteprima</span>

                <NewsCard
                  :item="{
                    image: 'https://picsum.photos/200',
                    userPic: 'https://picsum.photos/200',
                    title: tmpNotification.socialTitle,
                    description: tmpNotification.socialMessage,
                  }"
                />
              </b-card>
            </b-col>
          </b-row>
        </div>

        <!-- <span>
          To send the notification you need to satisfy
          <strong
            >{{ selected_option.length }} condition{{
              selected_option.length != 1 ? "s" : ""
            }}</strong
          >
        </span> -->
        <!-- <b-form-checkbox v-model="task" class="mt-5" disabled>
          Add Task
          <InfoPopover class="ml-1" text="task ecc." />
        </b-form-checkbox> -->
        <b-form-group v-if="task" class="mt-3">
          <template #label>
            Select task
            <InfoPopover
              class="ml-1"
              text="Lista dei ruoli etc. Lorem ipsum dolor sit amet"
            />
          </template>
          <div class="checkbox-container">
            <b-row
              v-for="option in checkboxList"
              :key="option.value"
              class="py-2 align-items-center"
            >
              <b-col md="6">
                <b-form-checkbox v-model="role_list" :value="option.value">
                  <strong class="ml-3">
                    {{ option.text }}
                  </strong>
                </b-form-checkbox>
              </b-col>
              <b-col md="6"> Task type </b-col>
            </b-row>
          </div>
        </b-form-group>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="notification_target_list" class="mt-5">
              <template #label>
                Define notification sender
                <!-- <InfoPopover
                  class="ml-1"
                  text="Lista dei ruoli etc. Lorem ipsum dolor sit amet"
                /> -->
              </template>
              <div class="checkbox-container">
                <div class="px-3">
                  <b-form-checkbox
                    disabled
                    v-for="option in options_invio"
                    :key="option.value"
                    v-model="role_list"
                    :value="option.value"
                    class="p-2"
                  >
                    <strong class="ml-3">
                      {{ option.text }}
                    </strong>
                  </b-form-checkbox>
                </div>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="notification_target_list" class="mt-5">
              <template #label>
                Define notification receiver
                <!-- <InfoPopover
                  class="ml-1"
                  text="Lista dei ruoli etc. Lorem ipsum dolor sit amet"
                /> -->
              </template>
              <div class="checkbox-container">
                <div class="px-3">
                  <b-form-checkbox
                    disabled
                    v-for="option in options_invio"
                    :key="option.value"
                    v-model="role_list"
                    :value="option.value"
                    class="p-2"
                  >
                    <strong class="ml-3">
                      {{ option.text }}
                    </strong>
                  </b-form-checkbox>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="mt-5 mb-5">
          <b-button
            @click="saveNotification"
            squared
            variant="primary"
            size="lg"
            class="big-button"
            >Confirm</b-button
          >
        </div>
        <div class="mt-5 mb-5"></div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { Mentionable } from "vue-mention";
import AxiosService from "../../axiosServices/AxiosService";
import NewsCard from "@/components/NewsCard";

export default {
  components: { Mentionable, NewsCard },
  service: null,
  serviceChanging: null,
  campaignService: null,
  created() {
    this.service = new AxiosService("Notification/GetNotificationType");
    this.campaignService = new AxiosService("Campaign");

    this.service.read().then((res) => {
      res.forEach((element) => {
        this.options.push(element);
      });
    });

    this.getNotifications();

    // this.service.readCustomEndpoint("Setting").then((res) => {
    //   if (res) {
    //     this.url_demo = res.url_demo;
    //   } else {
    //     console.log("ERROr calling Setting");
    //   }
    // });
    this.isDebugFunction();
  },

  mounted() {
    if (!this.isNewCampaign) {
      this.getUsersByCampaign();
    }
    this.campaignService
      .readById(this.$route.params.campaignId)
      .then((data) => {
        this.campaignName = data.title["it-IT"];
      });
  },
  data() {
    return {
      campaignName: "",

      isDebug: null,
      time: {
        ore: 0,
        minuti: 0,
      },
      selected_option: null,
      options: [
        {
          filter: null,
          placeholders: [],
          value: null,
          text: "Select notification type",
        },
      ],

      checkboxList: [],

      currentNotification: null,

      tmpNotification: {
        ID: 0,
        title: "",
        objectsID: [],
        notificationType: null,
        notificationStyle: "base",
        message: "",
        allObjects: false,
        Enabled: false,
        CustomValue: 0,
      },

      options_invio: [
        {
          value: "prova",
          text: "Top manager",
        },
        {
          value: "prova2",
          text: "Manager",
        },
        {
          value: "prova3",
          text: "User",
        },
      ],

      notifications_list: [],

      title_lang: "it-IT",
      description_lang: "it-IT",
      lang_options: [
        { text: "It", value: "it-IT" },
        { text: "En", value: "en-EN" },
      ],
      notification_target_list: [],

      role_list: [],

      task: false,

      isNewCampaign: this.$route.params.campaignId == null,

      url_demo: "",
      usersList: [{ value: null, text: "Seleziona Utente" }],
      userSelected: null,

      notificationStyleOptions: [
        { value: "base", text: "Base" },
        { value: "feedback", text: "Feedback" },
        { value: "relazione", text: "Relazione" },
      ],
      rating: 0,
      selectedEmoji: null,
      emojis: ["👍", "❤️", "💡", "😃"],
    };
  },
  methods: {
    setRating(star) {
      this.rating = star;
    },
    setEmoji(index) {
      this.selectedEmoji = index;
    },
    getUsersByCampaign() {
      this.usersList.splice(1);
      if (this.$route.params.campaignId) {
        this.service
          .readCustomEndpoint(
            "CampaignManager/GetUsersInCampaign",
            this.$route.params.campaignId
          )
          .then((res) => {
            res.forEach((element) => {
              this.usersList.push(element);
            });
          });
      } else {
        console.log("FAIL ");
      }
    },
    //     getUrlDemo() {
    //       if (this.userSelected != null) {
    //         this.service.readCustomEndpoint("Setting").then((res) => {
    //           if (res) {
    //             this.url_demo =
    //               res.url_demo +
    //               "/debug/" +
    //               this.$route.params.campaignId +
    //               "/" +
    //               this.userSelected +
    //               "/notification";
    //           } else {
    //             console.err("ERROr calling Setting");
    //           }
    //         });
    //       } else {
    //         this.url_demo = "";
    //         // this.service.readCustomEndpoint("Setting").then((res) => {
    //         //   this.url_demo = res.url_demo + "login";
    //         // });
    //       }
    //     },
    //     resetDataSidebarIframe() {
    //       this.userSelected = null;
    //       this.url_demo = "";
    //     },
    isDebugFunction() {
      this.isDebug = null;
      this.service
        .readCustomEndpoint(
          "CampaignManager/IsDebugEnabledOnCampaign/" +
            this.$route.params.campaignId
        )
        .then((res) => {
          this.isDebug = res;
          console.log("IS DEBUG? ", this.isDebug);
        });
    },
    getNotifications() {
      this.service
        .readCustomEndpoint(
          "Notification/GetNotifications",
          this.$route.params.campaignId
        )
        .then((res) => {
          if (res) {
            this.notifications_list.splice(0);
            this.notifications_list = res;
          } else {
            this.$errorToast();
            return false;
          }
        });
    },

    saveNotification() {
      if (!this.currentNotification) {
        this.service
          .postCustomEndpoint(
            "Notification/SetNewNotification",
            this.$route.params.campaignId,
            this.tmpNotification
          )
          .then((res) => {
            if (res) {
              this.getNotifications();
              this.$successToast();
            } else {
              this.getNotifications();
              this.$errorToast();
              return false;
            }
          });
      } else {
        this.service
          .putCustomEndpoint(
            "/Notification/UpdateNotification",
            this.tmpNotification.ID,
            this.tmpNotification
          )
          .then((res) => {
            if (res) {
              this.getNotifications();
              this.$successToast();
            } else {
              this.getNotifications();
              this.$errorToast();
              return false;
            }
          });
      }
    },
    toggleEnableNotification(notification) {
      this.service
        .putCustomEndpoint(
          "/Notification/UpdateNotification",
          notification.ID,
          notification
        )
        .then((res) => {
          if (res) {
            this.getNotifications();
            this.$successToast();
          } else {
            this.getNotifications();
            this.$errorToast();
            return false;
          }
        });
    },
    deleteNotification(id) {
      console.log(id);
      this.service
        .deleteCustomEndpoint("/Notification/DeleteNotification", id)
        .then((res) => {
          if (res) {
            this.getNotifications();
            this.$successToast();
          } else {
            this.getNotifications();
            this.$errorToast();
            return false;
          }
        });
    },
    getAwards() {
      this.serviceChanging = new AxiosService("Adward");
      this.serviceChanging
        .read([{ param: "idCampagna", value: this.$route.params.campaignId }])
        .then((res) => {
          res.forEach((element) => {
            let obj = {
              text: element.title["it-IT"],
              value: element.id,
            };
            this.checkboxList.push(obj);
          });
        });
    },
    getBadges() {
      this.serviceChanging = new AxiosService("Badge");
      this.serviceChanging
        .read([{ param: "idCampagna", value: this.$route.params.campaignId }])
        .then((res) => {
          res.forEach((element) => {
            let obj = {
              text: element.title["it-IT"],
              value: element.id,
            };
            this.checkboxList.push(obj);
          });
        });
    },
    getKpis() {
      this.service
        .getActionUtility("GetKpiActions", this.$route.params.campaignId)
        .then((res) => {
          res.forEach((element) => {
            let obj = {
              text: element.nome,
              value: element.id,
            };
            this.checkboxList.push(obj);
          });
        });
    },
    getLearning() {
      this.service
        .getActionUtility("GetLearningActions", this.$route.params.campaignId)
        .then((res) => {
          res.forEach((element) => {
            let obj = {
              text: element.nome,
              value: element.id,
            };
            this.checkboxList.push(obj);
          });
        });
    },
    getLanding() {
      this.service
        .getActionUtility("GetLandingActions", this.$route.params.campaignId)
        .then((res) => {
          res.forEach((element) => {
            let obj = {
              text: element.nome,
              value: element.id,
            };
            this.checkboxList.push(obj);
          });
        });
    },
    getSocial() {
      this.service
        .getActionUtility("GetSocialActions", this.$route.params.campaignId)
        .then((res) => {
          res.forEach((element) => {
            let obj = {
              text: element.nome,
              value: element.id,
            };
            this.checkboxList.push(obj);
          });
        });
    },
    getClassroom() {
      this.service
        .readCustomEndpoint(
          "ClassRoom/GetListClassrooms",
          this.$route.params.campaignId
        )
        .then((res) => {
          res.forEach((element) => {
            element.text = element.title["it-IT"];
            element.value = element.id;

            this.checkboxList.push(element);
          });
        });
    },
    resetSidebar() {
      this.checkboxList.splice(0);
      this.time.ore = this.time.minuti = 0;
      this.currentNotification = null;
      this.tmpNotification = {
        ID: 0,
        title: "",
        objectsID: [],
        notificationType: null,
        message: "",
        allObjects: false,
        Enabled: false,
        CustomValue: 0,
      };
    },
  },
  computed: {
    selected_option_obj() {
      let obj = {
        filter: "",
        text: "",
        value: null,
        placeholders: [],
      };
      this.options.forEach((element) => {
        if (element.value == this.tmpNotification.notificationType) {
          obj.filter = element.filter;
          obj.text = element.text;
          obj.value = element.value;
          if (typeof element.placeholders !== "undefined") {
            element.placeholders.forEach((item) => {
              obj.placeholders.push({ label: item, value: item });
            });
          }
        }
      });
      return obj;
    },
  },
  watch: {
    selected_option_obj: {
      handler() {
        this.time.ore = this.time.minuti = 0;
        this.checkboxList.splice(0);
        switch (this.selected_option_obj.filter) {
          case "award":
            this.getAwards();
            break;
          case "badge":
            this.getBadges();
            break;
          case "classroom":
            this.getClassroom();
            break;
          case "kpi":
            this.getKpis();
            break;
          case "learning":
            this.getLearning();
            break;
          case "social":
            this.getSocial();
            break;
          case "task":
            break;
        }
      },
    },
    time: {
      deep: true,
      handler() {
        this.tmpNotification.CustomValue = 0;

        if (Number(this.time.ore) != 0 || Number(this.time.minuti) != 0) {
          this.tmpNotification.CustomValue =
            Number(this.time.ore) * 60 + Number(this.time.minuti);
        } else {
          this.tmpNotification.CustomValue = 0;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  aspect-ratio: 9/18;
  margin-top: 10px;
  padding: 30px 0;
  background-color: var(--whappy-primary);
  color: var(--whappy-text-primary);
  font-size: 1.5rem;
  text-align: center;
  border-radius: 25px;

  &-base {
    padding: 60px 0 0 0;
  }
}

.top-avatar,
.bottom-avatar {
  width: 130px;
  height: 130px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.rating-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--whappy-primary-dark);
  border-radius: 25px;
}
.star {
  font-size: 2rem;
  cursor: pointer;
  margin: 0 5px;
  color: lightgray;
}
.star.filled {
  color: gold;
}

.emoji-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--whappy-primary-dark);
  border-radius: 25px;
}
.emoji {
  font-size: 2rem;
  cursor: pointer;
  margin: 0 10px;
  filter: saturate(0.1);
}
.emoji.selected {
  filter: none;
}
</style>
